
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { WordsQuestionType } from '@/types';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Ref } from 'vue-property-decorator';
import RepeatButton from '../../base/RepeatButton.vue';
import StartTestButton from '../../base/StartTestButton.vue';
import FindWordsAnswerOption from './FindWordsAnswerOption.vue';
import FindWordsImage from './FindWordsImage.vue';
import TutorialMixin from '../TutorialMixin';
import { findWordsImages_a, findWordsImages_b } from '@/utils/helpers';

@Component({
  components: {
    FindWordsAnswerOption,
    FindWordsImage,
    RepeatButton,
    StartTestButton,
  },
})
export default class FindWordsTutorialContainer extends TutorialMixin {
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  idx = 0;
  answered = 0;
  totalQuestions = this.wordsModule.tutorialData.length;
  disableButtons = true;
  preventDoubleClick = false;
  enableSolution = false;
  showWrongAnswers = false;
  showSolution = false;
  started = false;
  tutorialEndSound?: HTMLAudioElement;
  hasImage = false;
  currentIdx = -1;
  images = ['Mund', 'Eis', ...this.formImages];
  loadingImg = false;
  @Ref() currentAudio!: HTMLAudioElement;
  currentAudios: string[] = [];
  audioSrc = '/audio/click.mp3';
  audioIdx = 0;
  onSoundPlayed: (audioSrc: string) => void = () => null;
  answerPlaying = false;
  canPlayTimeout = 0;

  get audios() {
    return {
      lookImage: '/audio/tutorials/u4/HELP246.mp3',
      clickCorrectWord: '/audio/tutorials/u4/HELP247.mp3',
      clickOrangeWord: '/audio/tutorials/u4/HELP264.mp3',
      rightWordHint: '/audio/tutorials/u4/HELP275.mp3',
      veryGood: '/audio/tutorials/u4/HELP288.mp3',
      super: '/audio/tutorials/u4/HELP3833.mp3',
      wrong: '/audio/tutorials/u4/HELP3834.mp3',
      readyGo: '/audio/tutorials/u4/HELP3836.mp3',
      default: '/audio/click.mp3',
    };
  }

  get formImages(): string[] {
    return this.wordsModule.chosenForm === 'a'
      ? findWordsImages_a
      : findWordsImages_b;
  }

  get currentQuestion(): WordsQuestionType {
    return this.wordsModule.tutorialQuestions[this.idx];
  }

  get isEnd(): boolean {
    return this.answered === this.totalQuestions;
  }

  get format(): string {
    return this.wordsModule.imageFormat;
  }

  setHasImage(): void {
    this.hasImage = true;
  }

  resetImage(): void {
    this.hasImage = false;
  }

  playNextQuestion(): void {
    if (this.idx === 0) {
      this.playIntro();
    } else if (!this.isEnd) {
      this.showSolution = false;
      this.showWrongAnswers = false;
      this.preventDoubleClick = false;
      this.disableButtons = true;
      this.enableSolution = false;
      setTimeout(() => this.playSound([this.audios.clickCorrectWord]), 1000);
    }
  }

  repeatQuestion(): void {
    this.userModule.stopActiveAudio();
    this.showSolution = false;
    this.showWrongAnswers = false;
    this.preventDoubleClick = false;
    this.disableButtons = true;
    this.enableSolution = false;
    this.idx = 0;
    this.answered = 0;
    this.playNextQuestion();
  }

  playIntro(): void {
    this.tutorialEndSound = new Audio(this.audios.readyGo);
    const { lookImage, clickCorrectWord } = this.audios;
    this.playSound([lookImage, clickCorrectWord], undefined);
  }

  playCorrectAnswer(): void {
    this.showSolution = true;
    const { super: _super } = this.audios;
    const onSoundPlayed = (audioSrc: string) => {
      if (audioSrc === _super) {
        this.idx++;
        this.answered++;
        this.answerPlaying = false;
        if (this.isEnd) {
          if (this.isTutorialOnlyVersion) {
            this.redirectOnTutorialOnly();
          } else {
            this.wordsModule.finishedTutorial();
            this.userModule.setActiveAudio(this.tutorialEndSound!);
            setTimeout(() => this.tutorialEndSound!.play(), 1000);
          }
        } else {
          this.playNextQuestion();
        }
      }
    };
    setTimeout(() => this.playSound([_super], onSoundPlayed), 1500);
  }

  playWrongAnswer(): void {
    const { wrong, rightWordHint } = this.audios;
    this.playSound([wrong, rightWordHint], (audioSrc: string) => {
      switch (audioSrc) {
        case wrong:
          this.answerPlaying = false;
          this.showSolution = true;
          this.showWrongAnswers = true;
          this.currentIdx = -1;
          break;
        case rightWordHint:
          setTimeout(async () => {
            this.idx++;
            this.answered++;
            if (this.isEnd) {
              if (this.isTutorialOnlyVersion) {
                this.redirectOnTutorialOnly();
              } else {
                setTimeout(async () => {
                  this.userModule.setActiveAudio(this.tutorialEndSound!);
                  this.tutorialEndSound!.play();
                  this.wordsModule.finishedTutorial();
                }, 1000);
              }
            } else {
              this.playNextQuestion();
            }
          }, 1000);
          break;
      }
    });
  }

  onCanPlay() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout = window.setTimeout(
      this.onCanPlayThrough.bind(this),
      6 * 1000,
    );
  }

  onCanPlayThrough() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout && (this.canPlayTimeout = 0);
    if (this.audioSrc !== this.audios.default) {
      this.userModule.setActiveAudio(this.currentAudio);
      this.currentAudio.play();
    }
  }

  playSound(
    sounds: string[] | undefined = undefined,
    onSoundPlayed: (audioSrc: string) => void = this.onSoundPlayed,
  ) {
    sounds && (this.currentAudios = sounds);
    sounds && (this.audioSrc = this.audios.default);
    onSoundPlayed !== this.onSoundPlayed &&
      (this.onSoundPlayed = onSoundPlayed);

    this.audioIdx = this.currentAudios.indexOf(
      this.audioSrc,
      this.audioIdx + 1,
    );
    const nextAudioIdx = this.audioIdx + 1;

    onSoundPlayed?.(this.audioIdx >= 0 ? this.audioSrc : this.audios.default);

    if (nextAudioIdx < this.currentAudios.length) {
      if (this.audioSrc === this.currentAudios[nextAudioIdx]) {
        this.currentAudio.play();
      } else {
        this.audioSrc = this.currentAudios[nextAudioIdx];
      }
      this.audioSrc = this.currentAudios[nextAudioIdx];
    } else {
      if (this.audioSrc === this.audios.clickCorrectWord) {
        this.disableButtons = false;
      }
    }
  }

  answerQuestion(answer: string, idx: number): void {
    if (this.answerPlaying) return;
    this.answerPlaying = true;
    this.preventDoubleClick = true;
    this.enableSolution = false;
    this.disableButtons = true;
    if (answer === this.currentQuestion.solution) {
      this.playCorrectAnswer();
    } else {
      this.currentIdx = idx;
      setTimeout(() => this.playWrongAnswer(), 500);
    }
  }

  beforeDestroy() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
  }
}
