var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isEnd)?_c('v-col',{staticClass:"answer-row d-flex justify-center",class:_vm.started ? 'top-margin' : '',attrs:{"cols":"12"}},[(_vm.started)?_c('RepeatButton',{attrs:{"isDisabled":_vm.showAnswer || _vm.disableButtons},nativeOn:{"click":function($event){return _vm.repeatQuestion.apply(null, arguments)}}}):_vm._e(),(!_vm.started)?_c('StartTestButton',{on:{"start":function($event){_vm.started = true;
      _vm.playIntro();}}}):_vm._e(),(_vm.started)?_vm._l((_vm.currentQuestion.options),function(option,idx){return _c('LettersTestAnswerOption',{key:`option-${idx}`,class:{
        disabledButton: _vm.disableButtons,
        solution: option === _vm.currentQuestion.solution,
        wrongAnswer: option != _vm.currentQuestion.solution,
        highlight: option === _vm.currentQuestion.solution && _vm.showAnswer,
        darken: option != _vm.currentQuestion.solution && _vm.showAnswer,
        disableMultipleClick:
          _vm.preventDoubleClick &&
          !(option === _vm.currentQuestion.solution && _vm.enableSolution),
      },attrs:{"letter":option,"disabled":_vm.disableButtons,"isTutorial":true},nativeOn:{"click":function($event){return _vm.answerQuestion(option)}}})}):_vm._e(),_c('audio',{ref:"currentAudio",attrs:{"src":_vm.audioSrc},on:{"ended":_vm.playSound}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }