
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import WordsModule from '../../../store/modules/WordsModule';

@Component
export default class FindWordsImage extends Vue {
  @Prop() image!: string;
  @Prop() alt!: string;

  wordsModule: WordsModule = getModule(WordsModule, this.$store);

  @Emit()
  loaded(): void {
    return;
  }

  @Emit()
  loadStart(): void {
    return;
  }
}
