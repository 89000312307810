import { render, staticRenderFns } from "./FindWordsTutorialContainer.vue?vue&type=template&id=2f0f8326&scoped=true&"
import script from "./FindWordsTutorialContainer.vue?vue&type=script&lang=ts&"
export * from "./FindWordsTutorialContainer.vue?vue&type=script&lang=ts&"
import style0 from "./FindWordsTutorialContainer.vue?vue&type=style&index=0&id=2f0f8326&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0f8326",
  null
  
)

export default component.exports