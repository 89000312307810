
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { WordsQuestionType } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import FindWordsAnswerOption from './FindWordsAnswerOption.vue';
import FindWordsImage from './FindWordsImage.vue';
import RetryMixin from '../RetryMixin';

@Component({
  components: {
    FindWordsAnswerOption,
    FindWordsImage,
    TestEndEmoji,
    TutorialEndEmoji,
  },
})
export default class FindWordsContainer extends RetryMixin {
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  @Ref() testEndSound!: HTMLAudioElement;

  start = 0;
  stop = 0;
  disableButtons = true;
  hasImage = false;
  answerLocked = false;
  // images =
  //   this.wordsModule.chosenForm === 'a' ? findWordsImages_a : findWordsImages_b;

  get currentQuestion(): WordsQuestionType {
    return this.wordsModule.currentQuestion;
  }

  get isEnd(): boolean {
    return (
      this.wordsModule.overallQuestions ===
      this.wordsModule.result.questionsAnswered
    );
  }

  get format(): string {
    return this.wordsModule.imageFormat;
  }

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 6500);
  }

  startInitial(): void {
    this.start = performance.now();
  }

  setHasImage(): void {
    this.hasImage = true;
    // INFO unlock answer here when image is loaded...
    this.answerLocked = false;
    // INFO start timer here when image is loaded...
    this.start = performance.now();
  }

  resetImage(): void {
    this.hasImage = false;
  }

  async updated(): Promise<void> {
    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.wordsModule.saveStatus(
          this.userModule.currentUser._id,
        );

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.wordsModule.finishedAndSavedTest();
        }
      } catch (error: any) {
        this.wordsModule.resetDone();
        console.error(
          '[WORDS] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  answerQuestion(answer: string): void {
    if (this.answerLocked) return;
    this.answerLocked = true;
    this.stop = performance.now();
    const solution = this.currentQuestion.solution;

    const time = this.stop - this.start;
    setTimeout(
      () =>
        this.wordsModule.addAnswer({
          solution: solution,
          answer: answer,
          time: Math.round(time),
          isCorrect: answer === solution,
        }),
      300,
    );
  }
}
