
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class LettersTestAnswerOption extends Vue {
  @Prop() letter!: string;
  @Prop() isPlaying!: boolean;
  @Prop({ default: false }) isTutorial!: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    clickSound.play();
    this.showOrangeBorder = true;
    if (this.isTutorial) {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 300);
    } else {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 100);
    }
  }
}
