
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class FindWordsAnswerOption extends Vue {
  @Prop() word!: string;
  @Prop({ default: false }) isTutorial!: boolean;
  @Prop({ default: false }) answerLocked?: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    if (this.answerLocked) return;
    clickSound.play();
    if (this.isTutorial) {
      // setTimeout(async () => {
      //   this.showOrangeBorder = false;
      // }, 1000);
    } else {
      this.showOrangeBorder = true;
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 300);
    }
  }
}
