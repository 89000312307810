var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[(!_vm.isEnd)?_c('v-col',{staticClass:"words-answer d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row justify-center align-center",attrs:{"cols":"12","sm":"10","md":"8","lg":"7","xl":"5"}},[(_vm.started)?_c('RepeatButton',{attrs:{"isDisabled":_vm.showSolution || _vm.disableButtons},nativeOn:{"click":function($event){return _vm.repeatQuestion.apply(null, arguments)}}}):_vm._e(),(!_vm.started && _vm.loadingImg)?_c('v-progress-circular',{staticClass:"center-component",attrs:{"value":100,"size":"80","color":"primary","indeterminate":""}}):_vm._e(),(!_vm.started && !_vm.loadingImg)?_c('StartTestButton',{on:{"start":function($event){_vm.started = true;
        _vm.playIntro();}}}):_vm._e(),(_vm.started)?[_c('FindWordsImage',{staticClass:"mt-2",attrs:{"image":`/images/find-words/${_vm.currentQuestion.solution}.${_vm.format}`,"alt":_vm.currentQuestion.solution},on:{"loaded":_vm.setHasImage,"load-start":_vm.resetImage}}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasImage),expression:"hasImage"}],staticClass:"d-flex flex-column justify-center align-center pr-4 pr-sm-7 pr-md-7 pr-lg-7 pr-xl-6 mt-2",attrs:{"cols":"7","sm":"6","md":"6"}},_vm._l((_vm.currentQuestion.options),function(option,idx){return _c('FindWordsAnswerOption',{key:`option-${idx}`,class:{
            disabledButton: _vm.disableButtons,
            solution: option === _vm.currentQuestion.solution,
            wrongAnswer: option != _vm.currentQuestion.solution,
            darken:
              option != _vm.currentQuestion.solution &&
              (_vm.showWrongAnswers || _vm.currentIdx === idx),
            highlight: option === _vm.currentQuestion.solution && _vm.showSolution,
            disableMultipleClick:
              _vm.preventDoubleClick &&
              !(option === _vm.currentQuestion.solution && _vm.enableSolution),
          },attrs:{"word":option,"isTutorial":true,"answerLocked":_vm.answerPlaying},nativeOn:{"click":function($event){return _vm.answerQuestion(option, idx)}}})}),1)]:_vm._e()],2):_vm._e(),_c('audio',{ref:"currentAudio",attrs:{"src":_vm.audioSrc},on:{"ended":() => _vm.playSound(),"canplay":_vm.onCanPlay,"canplaythrough":_vm.onCanPlayThrough}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }