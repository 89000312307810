
import router from '@/router';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NotFoundContainer extends Vue {
  get leo(): string {
    return require('@/assets/images/lion.png');
  }

  goBack(): void {
    router.back();
  }
}
