
import { Client } from '@/api/types';
import FantasyModule from '@/store/modules/FantasyModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import platform from 'platform';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(3) },
    grade: { required },
  },
  components: { LoginInfoBar },
})
export default class StudentLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);

  isDisabled = false;
  username = '';
  password = '';
  grade = null;
  show = false;
  loading = false;

  testImage = '/images/fantasy/Max';
  hasImage = false;

  setHasImage(): void {
    this.hasImage = true;
  }

  defaultImages = [
    require('@/assets/images/lion_small.png'),
    require('@/assets/images/lion.png'),
    require('@/assets/images/palm_leaf.png'),
    require('@/assets/images/bubble.png'),
  ];

  setCorrectImageFormat(): void {
    this.fantasyModule.setImageFormat();
    this.wordsModule.setImageFormat();
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get licenseExpired(): boolean {
    return this.schoolModule.licenseExpired;
  }

  get hasNoValidTestsLeft(): boolean {
    return this.isTherapy ? this.schoolModule.hasNoValidTestsLeft : false;
  }

  get schoolId(): number {
    return this.schoolModule.school.schoolId!;
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParents(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  mounted() {
    const select = document.querySelector('#grade');
    select?.setAttribute('readonly', 'readonly');
  }

  async login(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;

        // 1 - get clienttype
        const clientType = this.schoolModule.clientType;

        // 2 - choose correct school suffix according to client type
        const schoolSuffix =
          clientType === Client.school
            ? `${this.grade}_${this.schoolId}`
            : `_${this.schoolId}`;

        const studentUsername =
          this.username.toLowerCase().trim() + schoolSuffix.toLowerCase();
        await this.userModule.login({
          username: studentUsername,
          password: this.password.trim(),
        });
        await this.userModule.whoAmI();

        if (this.isTherapy || this.isParents) {
          const studentGrade = this.userModule.currentUser.grade;
          if (studentGrade !== this.grade) {
            this.userModule.logout();
            throw new Error('grades do not match');
          }
        }
        //TODO add platform???
        try {
          const info = platform.parse(navigator.userAgent);
          await this.userModule.updateUser({
            data: {
              platform: {
                name: info.name ?? '',
                version: info.version ?? '',
                layout: info.layout ?? '',
                os: info.os?.toString() ?? '',
                description: info.description ?? '',
                ua: info.ua ?? '',
                product: info.product ?? '',
                manufacturer: info.manufacturer ?? '',
              },
            },
            id: this.userModule.currentUser._id,
          });
        } catch (error) {
          console.log('no user agent');
        }

        if (this.isParents) {
          this.$router.push({
            name: 'parentInfo',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        } else {
          this.$router.push({
            name: 'start',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        }
      } catch (error: any) {
        if (error?.message === 'grades do not match') {
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.login.error.grade').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.login.error.info').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.username = '';
    this.password = '';
    this.grade = null;
  }

  goToAdminLogin(): void {
    this.$router.push({
      name: 'school',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  get gradeItems(): string[] {
    return this.schoolModule.classes;
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.minLength &&
      errors.push('Dein Benutzername ist mind. 3 Buchstaben lang!');
    !this.$v.username.required && errors.push('Wie lautet dein Benutzername?');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength &&
      errors.push('Dein Passwort hat mind. 3 Buchstaben!');
    !this.$v.password.required && errors.push('Bitte gib dein Passwort ein!');
    return errors;
  }

  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('In welche Klasse gehst du?');
    return errors;
  }
}
